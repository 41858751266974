import { default as icons_45page6TtNXXHR3jMeta } from "/app/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91slug_93msfiAekumQMeta } from "/app/pages/[slug].vue?macro=true";
import { default as _503LDk9YpKaalMeta } from "/app/pages/503.vue?macro=true";
import { default as indexI7rbAxvpYAMeta } from "/app/pages/accessoires/index.vue?macro=true";
import { default as _91slug_936l1FdcHhFHMeta } from "/app/pages/agences/[slug].vue?macro=true";
import { default as indexjliwfSTkLYMeta } from "/app/pages/agences/index.vue?macro=true";
import { default as indexNnUfSBY2UkMeta } from "/app/pages/contact/index.vue?macro=true";
import { default as indexrm6FkuIqOUMeta } from "/app/pages/espace-professionnels/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91id_932SevcVW9Z1Meta } from "/app/pages/mon-compte/reservations/[id].vue?macro=true";
import { default as index6vAELzZ5qaMeta } from "/app/pages/mon-compte/reservations/index.vue?macro=true";
import { default as index1c2RzLMlGCMeta } from "/app/pages/partenaires/index.vue?macro=true";
import { default as _91slug_93XKzejjAJ7fMeta } from "/app/pages/recrutement/[slug].vue?macro=true";
import { default as indexyw4qQpBDfiMeta } from "/app/pages/recrutement/index.vue?macro=true";
import { default as accessoiresEQ5V8VmoG4Meta } from "/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/accessoires.vue?macro=true";
import { default as demande07WACnC01oMeta } from "/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/demande.vue?macro=true";
import { default as index9ccLF06djrMeta } from "/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/index.vue?macro=true";
import { default as recapitulatif4xrDPnGlq1Meta } from "/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/recapitulatif.vue?macro=true";
import { default as indexHlYONHL1ppMeta } from "/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]/index.vue?macro=true";
import { default as confirmationoOxcVWnNp8Meta } from "/app/pages/reservation/[id]/confirmation.vue?macro=true";
import { default as erreur2AlHDEHnd4Meta } from "/app/pages/reservation/[id]/erreur.vue?macro=true";
import { default as component_45stub2rr9KsTCoBMeta } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub2rr9KsTCoB } from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "icons-page___fr",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/app/node_modules/.pnpm/@nuxtjs+svg-sprite@1.0.2_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: _91slug_93msfiAekumQMeta?.name ?? "slug___fr",
    path: "/:slug()",
    meta: _91slug_93msfiAekumQMeta || {},
    component: () => import("/app/pages/[slug].vue")
  },
  {
    name: "503___fr",
    path: "/503",
    meta: _503LDk9YpKaalMeta || {},
    component: () => import("/app/pages/503.vue")
  },
  {
    name: indexI7rbAxvpYAMeta?.name ?? "accessoires___fr",
    path: "/accessoires",
    meta: indexI7rbAxvpYAMeta || {},
    alias: ["/accessoires"],
    component: () => import("/app/pages/accessoires/index.vue")
  },
  {
    name: _91slug_936l1FdcHhFHMeta?.name ?? "agences-slug___fr",
    path: "/agences/:slug()",
    meta: _91slug_936l1FdcHhFHMeta || {},
    component: () => import("/app/pages/agences/[slug].vue")
  },
  {
    name: indexjliwfSTkLYMeta?.name ?? "agences___fr",
    path: "/agences",
    meta: indexjliwfSTkLYMeta || {},
    component: () => import("/app/pages/agences/index.vue")
  },
  {
    name: indexNnUfSBY2UkMeta?.name ?? "contact___fr",
    path: "/contact",
    meta: indexNnUfSBY2UkMeta || {},
    component: () => import("/app/pages/contact/index.vue")
  },
  {
    name: indexrm6FkuIqOUMeta?.name ?? "espace-professionnels___fr",
    path: "/espace-professionnels",
    meta: indexrm6FkuIqOUMeta || {},
    component: () => import("/app/pages/espace-professionnels/index.vue")
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login___fr",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: _91id_932SevcVW9Z1Meta?.name ?? "mon-compte-reservations-id___fr",
    path: "/mon-compte/reservations/:id()",
    meta: _91id_932SevcVW9Z1Meta || {},
    alias: ["/r/:id/details"],
    component: () => import("/app/pages/mon-compte/reservations/[id].vue")
  },
  {
    name: index6vAELzZ5qaMeta?.name ?? "mon-compte-reservations___fr",
    path: "/mon-compte/reservations",
    meta: index6vAELzZ5qaMeta || {},
    component: () => import("/app/pages/mon-compte/reservations/index.vue")
  },
  {
    name: index1c2RzLMlGCMeta?.name ?? "partenaires___fr",
    path: "/partenaires",
    meta: index1c2RzLMlGCMeta || {},
    component: () => import("/app/pages/partenaires/index.vue")
  },
  {
    name: _91slug_93XKzejjAJ7fMeta?.name ?? "recrutement-slug___fr",
    path: "/recrutement/:slug()",
    meta: _91slug_93XKzejjAJ7fMeta || {},
    component: () => import("/app/pages/recrutement/[slug].vue")
  },
  {
    name: indexyw4qQpBDfiMeta?.name ?? "recrutement___fr",
    path: "/recrutement",
    meta: indexyw4qQpBDfiMeta || {},
    component: () => import("/app/pages/recrutement/index.vue")
  },
  {
    name: accessoiresEQ5V8VmoG4Meta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle-accessoires___fr",
    path: "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()/accessoires",
    meta: accessoiresEQ5V8VmoG4Meta || {},
    component: () => import("/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/accessoires.vue")
  },
  {
    name: demande07WACnC01oMeta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle-demande___fr",
    path: "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()/demande",
    meta: demande07WACnC01oMeta || {},
    component: () => import("/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/demande.vue")
  },
  {
    name: index9ccLF06djrMeta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle___fr",
    path: "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()",
    meta: index9ccLF06djrMeta || {},
    component: () => import("/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/index.vue")
  },
  {
    name: recapitulatif4xrDPnGlq1Meta?.name ?? "reservation-category-depAgency-depTime-retTime-vehicle-recapitulatif___fr",
    path: "/reservation/:category()-:depAgency()-:depTime()-:retTime()-:vehicle()/recapitulatif",
    meta: recapitulatif4xrDPnGlq1Meta || {},
    component: () => import("/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]-[vehicle]/recapitulatif.vue")
  },
  {
    name: indexHlYONHL1ppMeta?.name ?? "reservation-category-depAgency-depTime-retTime___fr",
    path: "/reservation/:category()-:depAgency()-:depTime()-:retTime()",
    meta: indexHlYONHL1ppMeta || {},
    component: () => import("/app/pages/reservation/[category]-[depAgency]-[depTime]-[retTime]/index.vue")
  },
  {
    name: confirmationoOxcVWnNp8Meta?.name ?? "reservation-id-confirmation___fr",
    path: "/reservation/:id()/confirmation",
    meta: confirmationoOxcVWnNp8Meta || {},
    alias: ["/r/:id/confirmation"],
    component: () => import("/app/pages/reservation/[id]/confirmation.vue")
  },
  {
    name: erreur2AlHDEHnd4Meta?.name ?? "reservation-id-erreur___fr",
    path: "/reservation/:id()/erreur",
    meta: erreur2AlHDEHnd4Meta || {},
    component: () => import("/app/pages/reservation/[id]/erreur.vue")
  },
  {
    name: component_45stub2rr9KsTCoBMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub2rr9KsTCoB
  }
]