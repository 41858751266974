
import * as interventionRequestRuntime$FAI46dvJjw from '/app/node_modules/@rezo-zero/intervention-request-provider/dist/runtime/index.mjs'
import * as ipxRuntime$L0vYBdqPL7 from '/app/node_modules/.pnpm/@nuxt+image@1.8.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 375,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1440,
    "xxl": 1536,
    "2xl": 1536,
    "hd": 1920,
    "qhd": 2500
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": "1",
  "format": [
    "webp"
  ],
  "quality": 75
}

imageOptions.providers = {
  ['interventionRequest']: { provider: interventionRequestRuntime$FAI46dvJjw, defaults: undefined },
  ['ipx']: { provider: ipxRuntime$L0vYBdqPL7, defaults: undefined }
}
        