import revive_payload_client_XeyRBjeWil from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NYbGpq0Paa from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6MaJq2mSWL from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l50BquJOFs from "/app/node_modules/.pnpm/nuxt-site-config@2.2.9_@nuxt+devtools@1.5.2_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_sass@_2xndyz6wrhoyt462fbv2yewx64/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_Qs0kOYIowV from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pYHVesLQsL from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_dX7ARRCjSC from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6FZG0unaZl from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_uDNGgdekT7 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_uzRLMz68vG from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node_2ytfrxjbgwumghxuo3yufsnbpu/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_dBcy1Z8rar from "/app/node_modules/.pnpm/@nuxtjs+plausible@0.2.4_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import i18n_mvUpuixQ8Q from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.5_rollup@4.24.0_vue@3.5.10_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import _00_debug_CzQoA0myfc from "/app/plugins/00.debug.ts";
import _01_sentry_client_2rh1pDAIty from "/app/plugins/01.sentry.client.ts";
import _10_mingat_api_WlYmeaB8p8 from "/app/plugins/10.mingat-api.ts";
import _11_url_resolver_4Yu8OnWGIl from "/app/plugins/11.url-resolver.ts";
export default [
  revive_payload_client_XeyRBjeWil,
  unhead_NYbGpq0Paa,
  router_6MaJq2mSWL,
  _0_siteConfig_l50BquJOFs,
  payload_client_Qs0kOYIowV,
  navigation_repaint_client_pYHVesLQsL,
  check_outdated_build_client_dX7ARRCjSC,
  chunk_reload_client_6FZG0unaZl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uDNGgdekT7,
  plugin_uzRLMz68vG,
  plugin_client_dBcy1Z8rar,
  i18n_mvUpuixQ8Q,
  _00_debug_CzQoA0myfc,
  _01_sentry_client_2rh1pDAIty,
  _10_mingat_api_WlYmeaB8p8,
  _11_url_resolver_4Yu8OnWGIl
]