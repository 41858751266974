import validate from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/pages/runtime/validate.js";
import _02_45refresh_45token_45global from "/app/middleware/02.refresh-token.global.ts";
import _03_45current_45user_45state_45global from "/app/middleware/03.current-user-state.global.ts";
import _10_45menus_45global from "/app/middleware/10.menus.global.ts";
import _11_45content_45blocks_45global from "/app/middleware/11.content-blocks.global.ts";
import _12_45redirect_45trailing_45slash_45global from "/app/middleware/12.redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.1.2_m6smpams3nbhokjyep7gcdh3cy/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _02_45refresh_45token_45global,
  _03_45current_45user_45state_45global,
  _10_45menus_45global,
  _11_45content_45blocks_45global,
  _12_45redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}